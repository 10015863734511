import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/dev-site/dev-site/src/templates/Page/index.ts";
import { FaDownload, FaUpload } from 'react-icons/fa';
import ApiClients from './_api-clients.mdx';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Alert = makeShortcode("Alert");
const LinkGroupContainer = makeShortcode("LinkGroupContainer");
const LinkGroup = makeShortcode("LinkGroup");
const LinkGroupCard = makeShortcode("LinkGroupCard");
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...props} components={components} mdxType="MDXLayout">
    <Alert type="info" mdxType="Alert">
      <p>{`If you are a `}
        <a {...{
          "href": "/minfraud/minfraud-legacy/",
          "parentName": "p"
        }}>{`minFraud Legacy`}</a>
        {`
customer, please see our
`}
        <a {...{
          "href": "/minfraud/whats-new-in-minfraud-score-and-minfraud-insights/",
          "parentName": "p"
        }}>{`What's New in minFraud Score, minFraud Insights, and minFraud Factors`}</a>
        {`
document for a summary of the changes.`}</p>
    </Alert>
    <div {...{
      "id": "toc-overview"
    }}><h2 {...{
        "id": "overview",
        "parentName": "div"
      }}>{`Overview`}</h2>
      <p {...{
        "parentName": "div"
      }}>{`To learn more about the minFraud services and to purchase credits, please visit
the
`}
        <a {...{
          "href": "https://www.maxmind.com/en/solutions/minfraud-services",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer",
          "parentName": "p"
        }}>{`minFraud Overview page`}</a>
        {`.
To better understand the differences between each minFraud service, review our
`}
        <a {...{
          "href": "https://www.maxmind.com/en/solutions/minfraud-services/pricing",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer",
          "parentName": "p"
        }}>{`minFraud Service Comparison page`}</a>
        {`
and the
`}
        <a {...{
          "href": "/minfraud/api-documentation/responses#bodies",
          "parentName": "p"
        }}>{`API response body documentation`}</a>
        {`.`}</p></div>
    <div {...{
      "id": "toc-client-apis"
    }}><h2 {...{
        "id": "client-apis",
        "parentName": "div"
      }}>{`Client APIs`}</h2>
      <ApiClients mdxType="ApiClients" /></div>
    <div {...{
      "id": "toc-request-and-response-api-references"
    }}><h2 {...{
        "id": "request-and-response-api-references",
        "parentName": "div"
      }}>{`Request and Response API References`}</h2>
      <LinkGroupContainer heading="References" mdxType="LinkGroupContainer">
        <LinkGroup mdxType="LinkGroup">
          <LinkGroupCard heading="Requests" icon={FaDownload} to="/minfraud/api-documentation/requests" mdxType="LinkGroupCard" />
          <LinkGroupCard heading="Responses" icon={FaUpload} to="/minfraud/api-documentation/responses" mdxType="LinkGroupCard" />
        </LinkGroup>
      </LinkGroupContainer></div>
    <div {...{
      "id": "toc-additional-features"
    }}><h2 {...{
        "id": "additional-features",
        "parentName": "div"
      }}>{`Additional Features`}</h2>
      <div {...{
        "id": "toc-disposition-api",
        "parentName": "div"
      }}><h3 {...{
          "id": "disposition-api",
          "parentName": "div"
        }}>{`Disposition API`}</h3>
        <p {...{
          "parentName": "div"
        }}>{`We provide a `}
          <a {...{
            "href": "/minfraud/working-with-transaction-dispositions",
            "parentName": "p"
          }}>{`Dispositions API`}</a>
          {`
for use with Disposition setting from the Transaction Details page. Use this API
to integrate with your order management system, payment processor, or other
internal system so that manual review decisions made from the account portal are
available within your own systems. For more information about setting
dispositions from the account portal, please refer to the
`}
          <a {...{
            "href": "https://support.maxmind.com/hc/en-us/articles/4408762136603",
            "target": "_blank",
            "rel": "nofollow noopener noreferrer",
            "parentName": "p"
          }}>{`article on reviewing transactions on our knowledge base`}</a>
          {`.`}</p></div>
      <div {...{
        "id": "toc-report-transactions",
        "parentName": "div"
      }}><h3 {...{
          "id": "report-transactions",
          "parentName": "div"
        }}>{`Report Transactions`}</h3>
        <p {...{
          "parentName": "div"
        }}>{`If you are using our minFraud transaction fraud detection service, we also
encourage you to use our
`}
          <a {...{
            "href": "/minfraud/report-a-transaction",
            "parentName": "p"
          }}>{`report transaction service`}</a>
          {`. By reporting
transactions, you can help us significantly increase the amount of fraud we can
detect for you. We build custom machine learning models for each customer based
on reported transactions. We also manually review many reported transactions to
identify customizations and algorithmic improvements for each customer reporting
feedback.`}</p></div>
      <div {...{
        "id": "toc-device-tracking-add-on",
        "parentName": "div"
      }}><h3 {...{
          "id": "device-tracking-add-on",
          "parentName": "div"
        }}>{`Device Tracking Add-on`}</h3>
        <p {...{
          "parentName": "div"
        }}>{`You may optionally include the
`}
          <a {...{
            "href": "/minfraud/track-devices",
            "parentName": "p"
          }}>{`Device Tracking JavaScript`}</a>
          {` on your site that helps us
identify the device your customer is using to determine whether it has been used
in previous fraudulent transactions. The device information is factored into the
scores returned through the minFraud service.`}</p></div></div>
    <div {...{
      "id": "toc-timeout-errors"
    }}><h2 {...{
        "id": "timeout-errors",
        "parentName": "div"
      }}>{`Timeout Errors`}</h2>
      <p {...{
        "parentName": "div"
      }}>{`If you get timeout errors, first confirm if we experienced a service disruption
on our `}
        <a {...{
          "href": "https://status.maxmind.com/",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer",
          "parentName": "p"
        }}>{`status page`}</a>
        {` or on
`}
        <a {...{
          "href": "http://stats.pingdom.com/datuqzybqg5z",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer",
          "parentName": "p"
        }}>{`Pingdom`}</a>
        {`.`}</p>
      <p {...{
        "parentName": "div"
      }}>{`If there has not been a service disruption, we may be able to help you diagnose
your connection issues. Please use our
`}
        <a {...{
          "href": "https://github.com/maxmind/mm-network-analyzer",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer",
          "parentName": "p"
        }}><inlineCode {...{
            "parentName": "a"
          }}>{`mm-network-analyzer`}</inlineCode>
          {` program`}</a>
        {`,
which is designed to aid in diagnosing networking issues. It creates a zip file
containing various network checks like traceroute, DNS, etc. You can download
the latest version, run it, and send the resulting zip file to our support team
for review.`}</p>
      <p {...{
        "parentName": "div"
      }}>{`Please note, `}
        <inlineCode {...{
          "parentName": "p"
        }}>{`mm-network-analyzer`}</inlineCode>
        {` is not compatible with Windows, but you can
reach out to support with the following information (if available to you) to
help us investigate an issue:`}</p>
      <ul {...{
        "parentName": "div"
      }}>

        <li {...{
          "parentName": "ul"
        }}>{`Your requesting server’s IP address`}</li>


        <li {...{
          "parentName": "ul"
        }}>{`Which web service demonstrates the issue`}</li>


        <li {...{
          "parentName": "ul"
        }}>{`What integration method you’re using for that web service`}</li>


        <li {...{
          "parentName": "ul"
        }}>{`Any full error messages you’re receiving`}</li>


        <li {...{
          "parentName": "ul"
        }}>{`Date and time frame (including time zone) that you experienced the issue`}</li>


        <li {...{
          "parentName": "ul"
        }}>{`Your traceroute to `}
          <inlineCode {...{
            "parentName": "li"
          }}>{`minfraud.maxmind.com`}</inlineCode></li>

      </ul>
      <p {...{
        "parentName": "div"
      }}><a {...{
          "href": "https://support.maxmind.com/hc/en-us/requests/new",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer",
          "parentName": "p"
        }}>{`You can submit your support request on our knowledge base.`}</a></p></div>
    <div {...{
      "id": "toc-versioning"
    }}><h2 {...{
        "id": "versioning",
        "parentName": "div"
      }}>{`Versioning`}</h2>
      <p {...{
        "parentName": "div"
      }}>{`The minFraud Score, minFraud Insights, and minFraud Factors web services use two
part versions. Our current release is version 2.0. The major version number will
remain at 2 for the foreseeable future.`}</p>
      <p {...{
        "parentName": "div"
      }}>{`The minor version will only change if there are breaking changes in the web
service. A breaking change is one that breaks client code that follows the
documentation on this page. Breaking changes include changing the type of an
existing field, deleting a field entirely, or changing URIs.`}</p>
      <p {...{
        "parentName": "div"
      }}>{`All changes to the web services will be documented in the
`}
        <a {...{
          "href": "/minfraud/release-notes",
          "parentName": "p"
        }}>{`minFraud release notes`}</a>
        {`, whether or not the version
number is changed.`}</p>
      <p {...{
        "parentName": "div"
      }}>{`The following changes are not considered to be breaking changes and will not be
accompanied by a version number change:`}</p>
      <ul {...{
        "parentName": "div"
      }}>

        <li {...{
          "parentName": "ul"
        }}>{`Increased validation of inputs that causes a warning to be returned when there
previously was not one.`}</li>


        <li {...{
          "parentName": "ul"
        }}>{`Adding a new request or response field, either at the top level of the
structure or in one particular object such as the billing or `}
          <inlineCode {...{
            "parentName": "li"
          }}>{`credit_card`}</inlineCode>
          {`.
Client code should be written to allow for new fields to appear.`}</li>


        <li {...{
          "parentName": "ul"
        }}>{`Adding new values to enum fields such as processor.`}</li>


        <li {...{
          "parentName": "ul"
        }}>{`Adding or removing warning or error codes, and/or changing the body type for
an error. Client code should always check the `}
          <inlineCode {...{
            "parentName": "li"
          }}>{`Content-Type`}</inlineCode>
          {` header for any
error response. Client code should also be prepared to handle any valid HTTP
4xx or 5xx status code.`}</li>


        <li {...{
          "parentName": "ul"
        }}>{`Adding a new service with a new path.`}</li>

      </ul></div>
    <div {...{
      "id": "toc-license"
    }}><h2 {...{
        "id": "license",
        "parentName": "div"
      }}>{`License`}</h2>
      <p {...{
        "parentName": "div"
      }}>{`These services incorporate `}
        <a {...{
          "href": "https://www.geonames.org/",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer",
          "parentName": "p"
        }}>{`GeoNames`}</a>
        {` geographical
data, which is made available under the
`}
        <a {...{
          "href": "https://creativecommons.org/licenses/by/4.0/",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer",
          "parentName": "p"
        }}>{`Creative Commons Attribution 4.0 License`}</a>
        {`.`}</p></div>

  </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      